<template>
  <div>
    <CRow>
      <CCol sm="12">
        <AccountsTable
          :items="accounts"
          hover
          striped
          border
          small
          fixed
          caption="Cuentas"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AccountsTable from '../../components/administrator/AccountsTable.vue'
import ws from '../../services/account';
import store from '../../store'

export default {
  name: 'Accounts',
  components: { AccountsTable },
  data: function () {
		return {
            accounts: []
        }
  },
  mounted: async function() {
    this.loading();
    
    let response = await ws.getAccounts("active"); 

    if(response.type == "success"){
      this.accounts = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (tab){
      this.loading();
      let response = await ws.getAccounts(tab); 
      
      if(response.type == "success"){
        this.accounts = response.data;
      }

      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
