<template>
    <CModal :title="title" :color="color" size="lg" :show.sync="activeModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas <b>{{type == "active" ? 'activar' : (type == "deactive" ? 'desactivar' : 'eliminar')}}</b> la cuenta seleccionada?</p>
        <br>
        La cuenta <b>{{name}}</b> será {{type == "active" ? 'activada y se podrá volver a acceder a ella.' : (type == "deactive" ? 'desactivada y ya no se podrá acceder a la misma.' : 'eliminada de la base de datos.')}}

        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="closeModal" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" :color="type == 'active' ? 'info' : 'danger'">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>

import account_ws from '../../../services/account';
import store from '../../../store'
import moment from 'moment';

const today = new moment();
const today2 = new moment();

export default { 
  name: 'ActiveModal',
  props: {

  },
  components: {
    
  },
  data () {
    return {
      activeModal: false,
      title: "Desactivar Cuenta", 
      color: "info",
      isEdit: false,
      form: {},
      submitted: false,
      name: 'Hola',
      type: 'deactive',
      account: false
    }
  },
  mounted: async function() {

  },
  methods: {
    async activationModal (account) {
      this.activeModal = true;
      this.color = "info";
      this.title = 'Activar Cuenta';
      this.name = account.name;
      this.type = "active";
      this.account = account;
    },
    async desactivationModal (account) {
      this.activeModal = true;
      this.color = "danger";
      this.title = 'Desactivar Cuenta';
      this.name = account.name;
      this.type = "deactive";
      this.account = account;
    },
    async deleteModal (account) {
      this.activeModal = true;
      this.color = "danger";
      this.title = 'Eliminar Cuenta';
      this.name = account.name;
      this.type = "delete";
      this.account = account;
    },
    async destroy () {
      if(this.type == "deactive"){
        //Llamar a servicio para desactivar
    
        let response = await account_ws.deactiveAccount(this.account.id); 

        this.showToast(response.type, response.message);

        this.$emit("deactive", this.account );
      }
      else{
        if(this.type == "delete"){
          let response = await account_ws.deleteAccount(this.account.id); 

          this.showToast(response.type, response.message);

          this.$emit("delete", this.account );
        }
        else{
          let response = await account_ws.activeAccount(this.account.id); 

          this.showToast(response.type, response.message);

          this.$emit("active", this.account );
        }  
      }
      this.closeModal();
    },
    closeModal () {
      this.activeModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
  }
}
</script>